<template>
    <b-container fluid>
    <div>
      <b-navbar toggleable="lg" type="dark" variant="info">
        <b-navbar-brand to="/examblock">Exams Timetabler</b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav v-if="this.$route.name !== 'signin'">
          <b-navbar-nav>
            <b-nav-item to="/examblock">Exam Blocks</b-nav-item>
            <b-nav-item to="/admin">Subjects</b-nav-item>
            <b-nav-item to="/students">Students</b-nav-item>
            <b-nav-item to="/rooms">Rooms</b-nav-item>
          </b-navbar-nav>
          <b-navbar-nav class="ml-auto">
            <b-nav-item to="/users" right  v-if="person.userRole == 'ADMIN'">Users</b-nav-item>
            <b-nav-item-dropdown right>
              <template #button-content>
                <em>{{ person.username }}</em>
              </template>
              <b-dropdown-item to="/userprofile">Profile</b-dropdown-item>
              <b-dropdown-item to="/signin">Sign Out</b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </b-collapse> 
      </b-navbar>
    </div>
  </b-container>
</template>

<script>
//import { defineComponent } from '@vue/composition-api'
//import axios from 'axios';
import Person from '../modules/Person'

export default ({
    name: 'Toolbar',
    props: {
        
    },
    data() {
        return {
            person: Person
        }
    },
    setup() {
        //this.showModal = false;
    },
    created() {
        this.person = new Person();
    }
})
</script>
