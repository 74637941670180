<style scoped>
    .row {
        min-height: calc(100vh - 100px);
    }
</style>
<template>
    <b-container>
      <b-row class="justify-content-md-center" align-v="center">
        <b-col lg="4">
            <b-card class="bg-light">
        <b-form-group id="fieldset-name" label="User Name" label-for="username" >
            <b-form-input id="username" v-model="user.username" trim></b-form-input>
        </b-form-group>
        <b-form-group id="fieldset-password" label="Password" label-for="password" >
            <b-form-input id="password" v-model="user.password" type="password" @keyup.enter="onEnter"></b-form-input>
        </b-form-group>
        <div class="text-right">
            <b-button @click="signin()" variant="primary">Sign In</b-button> <hr>
            <!-- <b-button @click="signinDuo()" variant="primary">Sign In With Duo</b-button> <hr> -->
            <b-button @click="signinOneTimeCode()" variant="primary">Register For One Time Code</b-button> 
        </div>
        <b-alert :show="signinFailed" variant="danger">Username or password incorrect</b-alert>
        <b-alert :show="validationFailed" variant="danger">One time code incorrect</b-alert>
        </b-card>
        </b-col>
      </b-row>

      <b-modal v-model="showOneTimeCode" title="Validate Sign in" @ok="validateOneTimeCode" no-close-on-backdrop >
        <b-form-group id="fieldset-name" label="Enter Code" label-for="code" >
          <b-form-input id="one-time-code" v-model="oneTimeCode" ></b-form-input>
        </b-form-group>
      </b-modal>
        
      <b-modal v-model="showRegisterOTC" title="Register for Authenicator" @ok="validateOneTimeCode" no-close-on-backdrop>
        <p>Register for 2 factor authentication using Google Authenicator or similar.</p>    
        <p>Open Google Authenicator and either scan the QR code or enter the key below.</p>
        <p>You can enter the code generated in Google Authenticator.</p>
        <p>Key: {{ manualKey }}</p>
        <b-img :src="qrCode"></b-img>
        <b-form-group id="fieldset-name" label="Enter Code" label-for="code" >
          <b-form-input id="one-time-code" v-model="oneTimeCode" ></b-form-input>
        </b-form-group>
      </b-modal>
    </b-container>
</template>

<script>
  //import { defineComponent } from '@vue/composition-api'
  import axios from 'axios';
  import {router} from '../routes.js'

export default ({
    name: 'SignIn',
    props: {
        
    },
    data() {
        return {
            user: {
                username: "",
                password: ""
            },
            signinFailed: false,
            validationFailed: false,
            showOneTimeCode: false,
            showRegisterOTC: false,
            oneTimeCode: "",
            qrCode: "",
            manualKey: ""
        }
    },
    methods: {
        onEnter() {
            
            if (this.showOneTimeCode || this.showRegisterOTC) {
                this.validateOneTimeCode();
            }
            else {
                this.signin();
            }
          
        },
        signin() {
            let that = this;

            axios.post(window.API_URL + 'auth/signin', this.user).then(function(response) {
                console.log(response);
                sessionStorage.setItem('access_token', response.data.token);
                axios.defaults.headers.common['Access_Token'] = sessionStorage.getItem('access_token');
                //const user = this.parseJwt(sessionStorage.getItem('access_token'));
                if (response.data.hasKey) {
                    that.showOneTimeCode = true;
                }
                else {
                    router.replace({name: 'examblock'});
                }
            }).catch(function(reason) {
                console.log(reason);
                that.signinFailed = true;
            })
        },
        signinDuo() {
            // with 2fa
            let that = this;
            axios.post(window.API_URL + 'auth/signinduo', this.user).then(function(response) {
                console.log(response);
                sessionStorage.setItem('access_token', response.data.token);
                axios.defaults.headers.common['Access_Token'] = sessionStorage.getItem('access_token');
                //const user = this.parseJwt(sessionStorage.getItem('access_token'));
                sessionStorage.setItem('state', response.data.state);
                
                window.location.replace(response.data.promptUri);
                
            }).catch(function(reason) {
                console.log(reason);
                that.signinFailed = true;
            })
        },
        signinOneTimeCode() {
            // with 2fa
            this.$bvModal.show('test-modal')
            let that = this;
            axios.post(window.API_URL + 'auth/signinonetimecode', this.user).then(function(response) {
                console.log(response);
                sessionStorage.setItem('access_token', response.data.token);
                axios.defaults.headers.common['Access_Token'] = sessionStorage.getItem('access_token');
                //const user = this.parseJwt(sessionStorage.getItem('access_token'));
                if (response.data.hasKey) {
                    that.showOneTimeCode = true;
                }
                else {
                    axios.get(window.API_URL + 'auth/OneTimeCodeQRCode').then(function(response) {
                        that.qrCode = response.data.qrCode;
                        that.manualKey = response.data.manualKey;

                        that.showRegisterOTC = true;
                    })
                }
            }).catch(function(reason) {
                console.log(reason);
                that.signinFailed = true;
            })
        },
        validateOneTimeCode() {
            const that = this;
            axios.get(window.API_URL + 'auth/validateOneTimeCode?pin=' + this.oneTimeCode).then(function(response) {
                console.log(response);
                if (response.data) {
                    sessionStorage.setItem('access_token', response.data);
                    axios.defaults.headers.common['Access_Token'] = sessionStorage.getItem('access_token');
                    //const user = this.parseJwt(sessionStorage.getItem('access_token'));
                    router.replace({name: 'examblock'});
                } else {
                    that.validationFailed = true;
                }
                
            }).catch(function(reason) {
                console.log(reason);
                that.signinFailed = true;
            })
        },
        parseJwt (token) {
            var base64Url = token.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
        }
    },
    created: function () {
        sessionStorage.removeItem('access_token');
        axios.defaults.headers.common['Access_Token'] = "";        
    },
    setup() {
        //this.showModal = false;
    },
})
</script>
